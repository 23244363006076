import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 11; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/25-jan-24-3/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function ShriRamPujan() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Shri Ram Pujan in the school</h1>

        <p>
          Chinmaya Vidyalaya joined the nation in celebrating the consecration
          of the Ram Mandir at Ayodhya.
          <br />
          <br />
          The students and teachers immersed themselves wholeheartedly in the
          joyous celebrations. The devotion and divinity made the moments even
          more memorable.
          <br />
          <br />
          Naam Ramayana, soulful Bhajans and Bhagavan Ram’s Aarti reverberated
          through the school.
          <br />
          <br />
          The Principal, Ms Archana Soni highlighted the lessons to be learnt
          from Shri Ram’s life and exhorted the students to discover the
          transformative power of faith and channel it to create positivity and
          goodness in the world.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
